<template>
  <ion-card padding-bottom>
    <ion-card-header>
      <ion-card-title>Qualités</ion-card-title>
    </ion-card-header>

    <ListeQualites
      :qualites="metier.qualites"
      lignes="none"
    />
  </ion-card>
</template>

<script>

import ListeQualites from './ListeQualites'
import {IonCard, IonCardHeader, IonCardTitle} from "@ionic/vue";
export default {
  name: 'MetierQualites',
  components: {
    ListeQualites,
    IonCard,
    IonCardTitle,
    IonCardHeader,
  },
  props: {
    metier: {
      required: true,
      type: Object
    }
  }
}
</script>
