<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>Titre obtenu</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <strong>{{metier.titre}}</strong>

      <div v-if="metier.matu">
        <h2>Maturité</h2>
        Possibilité d'obtenir une maturité professionnelle pendant
        l'apprentissage ou après l'obtention du CFC.
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from "@ionic/vue";

export default {
  name: 'MetierTitre',
  components: {
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent
  },
  props: {
    metier: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
  ion-card-content h2 {
    color: var(--ion-color-primary);
    font-weight: bold;
    margin : 2rem 0 .5rem ;
  }
</style>
