<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>Formation</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      {{metier.formations}}
      <h2>Durée</h2>
      {{metier.duree}}
    </ion-card-content>

  </ion-card>
</template>

<script>
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from "@ionic/vue";

export default {
  name: 'MetierFormations',
  components: {
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent
  },
  props: {
    metier: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
  ion-card-content h2 {
    color: var(--ion-color-primary);
    font-weight: bold;
    margin : 1.5rem 0 .5rem ;
  }

  ion-card-content h3 {
    color: var(--ion-color-dark);
    font-weight: bold;
    margin : 1rem 0 .5rem ;
  }

  ion-card-content ul {
    padding-left: 1.5rem;
  }
</style>
