<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$router.back()">
            <ion-icon :icon="chevronBackSharp"></ion-icon> Retour
          </ion-button>
        </ion-buttons>
        <ion-title>{{metier.nom}}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-card>
        <img v-if="metier.img" :src="imgUrl()" />
        <ion-card-header>
          <ion-card-title>{{metier.nom}}</ion-card-title>
        </ion-card-header>

        <ion-item
          v-if="metier.titre"
          color="dark"
          button
          lines="none"
          @click="toggleFav"
        >
          <ion-icon v-if="estFavoris"
                    :icon="starSharp"
                    slot="start"
                    color="primary"
          ></ion-icon>
          <ion-icon v-else
                    :icon="starOutline"
                    slot="start"
                    color="primary"
          ></ion-icon>

          <ion-label v-if="estFavoris">
            Retirer des favoris
          </ion-label>
          <ion-label v-else>
            Ajouter aux favoris
          </ion-label>
        </ion-item>

        <ion-item
          color="dark"
          button
          lines="none"
          @click="goToCarteMetier(id)"
        >
          <ion-icon :icon="navigate" slot="start"></ion-icon>
          <ion-label>Emplacement du stand</ion-label>
        </ion-item>

        <ion-card-content v-if="metier.description">
          {{metier.description}}
        </ion-card-content>

        <ion-item
          v-if="metier.titre"
          color="primary"
          lines="none"
          button
          :href="metier.lien"
        >
          <ion-icon :icon="linkOutline" slot="start"></ion-icon>
          <ion-label>Plus d'infos sur orientation.ch</ion-label>
        </ion-item>
      </ion-card>

      <MetierQualites v-if="metier.titre" :metier="metier" />

      <MetierFormations v-if="metier.titre" :metier="metier" />

      <MetierTitre v-if="metier.titre" :metier="metier" />

      <created-by />
    </ion-content>
  </ion-page>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonPage,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonItem,
    IonLabel,
    IonIcon,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton
} from "@ionic/vue";

import {
  chevronBackSharp,
  starSharp,
  starOutline,
  linkOutline,
  navigate
} from "ionicons/icons";

import MetierQualites from '../components/MetierQualites'
import MetierTitre from '../components/MetierTitre'
import MetierFormations from '../components/MetierFormations'
import CreatedBy from '@/components/CreatedBy'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'PageDetailMetiers',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonItem,
    IonLabel,
    IonIcon,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    CreatedBy,
    MetierFormations,
    MetierTitre,
    MetierQualites
  },
  setup() {
    return { chevronBackSharp, starSharp, starOutline, linkOutline, navigate };
  },
  props: {
    id: {
      required: true
      // type: Number
    }
  },
  computed: {
    ...mapGetters(['getMetier', 'isFavori']),
    estFavoris () {
      return this.isFavori(this.id)
    },
    metier() {
      return this.getMetier(this.id)
    }
  },
  methods: {
    ...mapActions(['toggleFavori', 'setCarteMetier']),
    toggleFav () {
      this.toggleFavori(this.id)
    },
    goToCarteMetier (idMetier) {
      this.setCarteMetier(idMetier)
      this.$router.push({ name: 'carte'})
    },
    goBack () {
      // TODO améliorer navigation retourner aux écrans de base
      // ou réinitialiser la navigation sur certaines pages
      // this.$router.back()
      this.$router.back()
    },
    imgUrl () {
      return require('@/assets/metiers/' + this.metier.img)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
