<template>
  <ion-list>
    <ion-list-header v-if="titre">
      <ion-label v-html="titre"></ion-label>
    </ion-list-header>
    <ion-item
      :lines="lignes"
      button
      v-for="qualite in qualitesAZ"
      @click="$router.push({name: 'ficheQualite', params: { id: qualite.id } })"
      :key="qualite.id"
    >
      <ion-label class="ion-text-wrap">{{qualite.libelle}}</ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import { IonList, IonListHeader, IonLabel, IonItem} from "@ionic/vue";
import {
  ribbonSharp
} from "ionicons/icons";

export default {
  name: 'ListeQualites',
  setup() {
    return { ribbonSharp };
  },
  components: {
     IonList, IonListHeader, IonLabel, IonItem
  },
  props: {
    titre: { required: false },
    qualites: { required: true },
    lignes: { required: false, default: 'full' }
  },
  computed: {
    /**
     * Retourne les qualités triés par libelle ASC
     * @returns Array
     */
    qualitesAZ () {
      let qualites = this.qualites

      if (qualites.length) {
        return qualites.sort((a, b) => a.libelle.localeCompare(b.libelle, 'fr'))
      }
      return []
    }
  }
}
</script>
